<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche (par : Email, Nom du Rapport, Univers)"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :server-items-length="options.total"
        :loading="loading"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="sendEmail(item.id)"
            >
              <v-icon color="primary">
                {{ icons.mdiEmailOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.id = item.id;
                dialogModel.reportTitle = item.title;
              "
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <del-confirmation
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :name="dialogModel.reportTitle"
        @openDialog="openDialog"
      ></del-confirmation>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "@axios";
import DelConfirmation from "@/components/common/modals/EmailReportDelConfirmation.vue";

import { mdiEmailOutline, mdiTrashCan } from "@mdi/js";
export default {
  name: "EmailReportList",
  components: { DelConfirmation },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 25,
        total: 0,
      },
      dialogModel: {
        dialog: false,
        id: -1,
        reportTitle: "",
      },
      headers: [
        {
          text: "Nom du Rapport",
          align: "start",
          value: "title",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Univers",
          value: "site_group",
        },
        {
          text: "Denier envoi",
          value: "last_time_sent",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiTrashCan,
        mdiEmailOutline,
      },
    };
  },
  watch: {
    // options: {handler(){this.updateData();}, deep:true,},
    // individual properties to avoid refreshing when options.total updates
    async "options.itemsPerPage"() {
      await this.updateData();
    },
    async "options.page"() {
      await this.updateData();
    },
    async "options.sortBy"() {
      await this.updateData();
    },
    async "options.sortDesc"() {
      await this.updateData();
    },
    async search() {
      this.options.page = 1;
      await this.updateData();
    },
    "$store.state.c2c.siteList": function () {
      this.updateData();
    },
  },
  methods: {
    async sendEmail(id) {
      this.loading = true;
      await axios.post("/email-reports/" + id + "/send/");
      this.loading = false;
      // todo: show snackbar
      // await this.$store.dispatch("emailReports/showSnackbar", {
      //   text: response.data.message,
      //   color: "success",
      // });
    },
    async updateData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      const queryParams = {
        page: page - 1,
        limit: itemsPerPage,
        ...(this.search ? { serach: this.search } : {}),
        ...(sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0
          ? {
              sort_by: sortBy[0],
              sort_desc: sortDesc[0],
            }
          : {}),
      };

      const { data } = await axios.get("/email-reports/", {
        params: queryParams,
      });
      this.loading = false;
      this.items = data.items;
      this.options.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>
