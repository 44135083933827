<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">
        Etes vous sur de vouloir supprimer ({{ name }})?
      </p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="deleteReport"
                  >Supprimer
                </v-btn>
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialog', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";

export default {
  name: "SiteDelConfirmation",
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteReport() {
      const { status } = await axios.delete(`/email-reports/${this.id}`);
      if (status === 204) {
        // await this.$store.dispatch("common/getSiteList");
        this.$emit("openDialog", false);
      }
    },
  },
};
</script>
